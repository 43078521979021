import React from "react"
import { PageProps } from "gatsby"
import { Query } from "../../../types/graphql-types"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PageTitle from "../../components/utils/PageTitle"
import ContactFormEn from "../../components/Contact/ContactFormEn"
import { en_description } from "../../components/const"

const Contact: React.FC<PageProps<Query>> = ({ location }) => {
  return (
    <Layout location={location} selectPageName={"Contact"}>
      <SEO
        title="Contact"
        description={en_description}
        lang={"en"}
        twitterTitle={"D Capital | Contact"}
      />
      <PageTitle title="Contact" />
      <ContactFormEn />
    </Layout>
  )
}

export default Contact
